<!-- Código html -->
<template>
    
    <!-- Contenedor principal -->
    <div id="contenedor_principal">

           <div class="titulo">
                API Pogen Data
            </div>

            <div class="parrafo">
                This tool is used primarly to gather user's information regarding their as locations, the locations' traffic by date and time. API responses will be returned in JSON format
            </div>

            <div class="parrafo">
                All request require an access token, therefore the first step is to acquire this token.
            </div>

            <div class="titulo1 mt2em">
                Getting an Access Token
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parameters:
                </div>                
                <ul>
                    <li><strong>email</strong>: Users' email</li>
                    <li><strong>password</strong>: Password</li>
                </ul>
                <div class="categoria">
                    Request's Details:
                </div>                
                <ul>
                    <li><strong>url</strong>: https://app.pogendata.com/api/login</li>
                    <li><strong>method</strong>: post</li>
                </ul>
                <div class="categoria">
                    Response:
                </div>                
                <ul>
                    <li>JSON object, the reply has 2 parameters, success
                        that would be 0 for a failure and 1 if the right 
                        credentials were provided, if sucess was 1 the
                        parameter api_key would contain a valid access token 
                        to be used
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="javascript">
/* Login function */
async function obtenerToken(email,password){

    /* Url to fecth the token */
    let url = 'https://app.pogendata.com/api/login';

    /* Create a FormData object to be sent in the request */
    let fd = new FormData();

    /* Add email and password to FormData object */
    fd.append("email",email);
    fd.append("password",password);

    /* Request */
    fetch(url,{
        body:fd,
        method:'post'
    }).then(respuesta=>{

        /* Get response as JSON */
        respuesta.json()
        .then(json=>{

            /* The api_key attribute contains the token */
            console.log(json.api_key);

        });
    })
    .catch(error=>console.log(error));
}
                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">

/* Function to fetech the token */                        
function obtenerToken($email,$password){

    $url = 'https://app.pogendata.com/api/login';

    /* Create array to store the parameters to be sent */
    $arregloDatos = array(
        'email'=>$email,
        'password'=>$password
    );

    /* Use the array object */
    $datos = http_build_query($arregloDatos);

    /* Array with cnx options */
    $opciones = array(
        'http'=>array(
            'method'=>'POST',
            'header'=>'Content-type: application/x-www-form-urlencoded',
            'content'=>$datos
        )
    );

    /* Request's Options */
    $contexto = stream_context_create($opciones);

    /* Make the request using these values: $url, false y context object */
    $respuesta = file_get_contents($url,false,$contexto);

    /* Transform JSON response into an array for easier parsing   */
    $json = json_decode($respuesta,flags:JSON_OBJECT_AS_ARRAY);

    /* Fetch token from api_key attribute */
    return $json['api_key'];
}
                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class="vb">
/* Clase modelo Token */
public class Token
{
    public String Api_key { get; set; }
}

/* Method to fetch token */
public static async Task&lt;String&gt;  Acceso(String usuario, String contrasenia)
{

    /* Use HttpUtility to create parameter object*/
    var query = HttpUtility.ParseQueryString(string.Empty);

    /* Add user and password */
    query["email"] = usuario;
    query["password"] = contrasenia;

    /* Object to String to be sent as query string */
    string queryString = query.ToString();

    /* Create full URL including API URI and parameters */
    String direccion = "https://app.pogendata.com/api/login?" + queryString;

    /* Create http client for the request */
    using (var clienteHttp = new HttpClient())
    {

        /* Make the request */
        var respuesta = await clienteHttp.PostAsync(direccion, null);

        /* Fetch response */
        var cadenaRespuesta = await respuesta.Content.ReadAsStringAsync();

        /* Transform the response into a token */
        var token = JsonConvert.DeserializeObject&lt;Token&gt;(cadenaRespuesta);

        /* Display token */
        return token.Api_key;

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# import additional libraries
import requests

# Method to fecth token
def obtenerToken(usuario, contrasenia):

    # Define request URL
    url = "https://app.pogendata.com/api/login"

    # Define request parameters
    parametros = {
        'email' : usuario,
        'password' : contrasenia
    }

    # Create request and get response
    respuesta = requests.post( url, params=parametros)

    # Response to JSON
    json = respuesta.json()

    # Retrieve token
    return json['api_key']
                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                List user's locations
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parameters:
                </div>                
                <ul>
                    <li>
                        <strong>Token</strong>, the valid access token obtained with login call it must sent 
                        on the request's header as Authorization bearer
                    </li>
                </ul>
                <div class="categoria">
                    Request's details:
                </div>                
                <ul>
                    <li><strong>url</strong>: https://app.pogendata.com/api/data/plazas</li>
                    <li><strong>Method</strong>: post</li>
                </ul>
                <div class="categoria">
                    Response:
                </div>                
                <ul>
                    <li>
                        JSON Obeject, contains an array with the list of all the user's locations
                    </li>
                </ul>                                
            </div>            

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="js">
/* Función para obtener las plazas asociadas al usuario */
async function obtenerPlazas(token){

    /* URL to fecth locations */
    let url = 'https://app.pogendata.com/api/data/plazas';

    /* Make request use POST method, include in HEADERS Authorization Bearer the API token */
    fetch(url,{
    method:'post',
    headers:{
        Authorization:`bearer ${token}`
    }
    }).then(respuesta=>{
    
    /* JSON response */
    respuesta.json()
    .then(json=>{

        /* Locations fetched as an array */
        console.log(json);

    });
    }).catch(error=>console.log(error));

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Function to fetch locations */
function obtenerPlazas($token){

    /* Request URL */
    $url = 'https://app.pogendata.com/api/data/plazas';

    /* Array with connection options */
    $opciones = array(
        'http'=>array(
            'method'=>'POST',
            'header'=>'Authorization: Bearer ' . $token
        )
    );

    /* Create request's context */
    $contexto = stream_context_create($opciones);

    /* Execute request */
    $respuesta = file_get_contents($url,false,$contexto);

    /* Resoponse to array */
    $json = json_decode($respuesta);

    /* Sent fecthed data */
    return $json;

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class=".NET">
/* Location Class */
public class Establecimiento
{
    public int Id { get; set; }
    public String Name { get; set; }
    public EstablecimientoAcceso Accesos { get; set; }
}

/* Traffic Entry Class */ 
public class EstablecimientoAcceso
{
    public int Id { get; set; }
    public String Name { get; set; }
    public String Color { get; set; }
}

/* Method to fetch location list */
public static async Task&lt;List&lt;Establecimiento&gt;&gt; Establecimientos(String token)
{

    /* API URI */
    String direccion = "https://app.pogendata.com/api/data/plazas";

    /* Create http client */
    using (var clienteHttp = new HttpClient())
    {

        /* Add access token to the header */
        clienteHttp.DefaultRequestHeaders.Add("Authorization",$"Bearer {token}");

        /* Execute request */
        var respuesta = await clienteHttp.PostAsync(direccion, null);

        /* Fetch the response  */
        var cadenaRespuesta = await respuesta.Content.ReadAsStringAsync();

        /* JSON to entries */
        var json = JsonConvert.DeserializeObject&lt;List&lt;Establecimiento&gt;&gt;(cadenaRespuesta);

        /* Send data */
        return json;
    }
}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Importar librerías adicionales
import requests

# Method to fetch the location list
def obtenerEstablecimientos(token):

    # API URI
    url = 'https://app.pogendata.com/api/data/plazas'

    # Request's Header
    encabezado = {
        'Authorization' : 'Bearer ' + token
    }

    # Execute request and fecth response
    respuesta = requests.post(url, headers=encabezado)

    # Respose to JSON
    json = respuesta.json()

    # Send data
    return json
                        </code>
                    </pre>                    
                </div>
            </div>

            <div class="titulo1">
                Locations' traffic data
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parameters:
                </div>                
                <ul>
                    <li><strong>plaza_id</strong>: locations id (integer valud)</li>
                    <li>
                        <strong>Token</strong>, the valid access token obtained with login call it must sent 
                        on the request's header as Authorization bearer
                    </li>                    
                </ul>
                <div class="categoria">
                    Request's Details:
                </div>                
                <ul>
                    <li><strong>url</strong>: https://app.pogendata.com/api/data/accesos</li>
                    <li><strong>method</strong>: post</li>
                </ul>
                <div class="categoria">
                    Response:
                </div>                
                <ul>
                    <li>JSON Object, list with the location's traffic log entries and its details.
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="js">
/* Función para obtener los accesos de una plaza */
async function obtenerAccesos(token,plaza_id){

    /* API URI */
    let url = 'https://app.pogendata.com/api/data/accesos';

    /* Create a FormData object to be sent in the request */
    let fd = new FormData();

    /* Add plaza_id to formData   */
    fd.append('plaza_id',plaza_id)

    /* Make request use POST method, include in HEADERS Authorization Bearer the API token */
    fetch(url,{
    method:'post',
    headers:{
        Authorization:`bearer ${token}`
    },
    body:fd
    }).then(respuesta=>{
    
    /* Fetch JSON object */
    respuesta.json()
    .then(json=>{

        /* Entries as an array */
        console.log(json);

    });
    }).catch(error=>console.log(error));

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Function to fecth entries for an specific location */
function obtenerAccesos($token,$plaza_id){

    /* API URI */
    $url = 'https://app.pogendata.com/api/data/accesos';

    /* Array with request's parameters */
    $arregloDatos = array(
        'plaza_id'=>$plaza_id
    );

    /* Array to request object */
    $datos = http_build_query($arregloDatos);        

    /* Array with connection options */
    $opciones = array(
        'http'=>array(
            'method'=>'POST',
            'header'=>array(
                'Authorization: Bearer ' . $token,
                'Content-type: application/x-www-for-urlencoded'
            ),
            'content'=>$datos
        )
    );

    /* Create request's context */
    $contexto = stream_context_create($opciones);

    /* Execute request */
    $respuesta = file_get_contents($url,false,$contexto);

    /* Resoponse to array */
    $json = json_decode($respuesta);

    /* Sent fecthed data */
    return $json;

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class="vb">
/* Class for entry */
public class ClaseAcceso
{
    public int Id { get; set; }
    public String Nombre { get; set; }
    public String Color { get; set; }
}

/* Method to fetch entries for the location */
public static async Task&lt;List&lt;ClaseAcceso&gt;&gt; Accesos(String token, int plaza_id)
{

    /* API URI */
    String direccion = "https://app.pogendata.com/api/data/accesos";

    /* Create object for parameters */
    var objetoParametros = HttpUtility.ParseQueryString(String.Empty);

    /* Add parameters */
    objetoParametros["plaza_id"] = $"{plaza_id}";

    /* Create Query String */
    var cadenaParametros = objetoParametros.ToString();

    /* Create URL for the request with API URI and Query String */
    direccion += "?" + cadenaParametros;

    /* Create httpClient */
    using (var clienteHttp = new HttpClient())
    {

        /* Add token to header's request */
        clienteHttp.DefaultRequestHeaders.Add("Authorization", $"Bearer {token}");

        /* Execute request */
        var respuesta = await clienteHttp.PostAsync(direccion, null);

        /* Fetch response as String */
        var cadenaRespuesta = await respuesta.Content.ReadAsStringAsync();

        /* Create a JSON */
        var json = JsonConvert.DeserializeObject&lt;List&lt;ClaseAcceso&gt;&gt;(cadenaRespuesta);

        /* Sent Data */
        return json;

    }

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# import additional libraries
import requests

# Method to fecth entries
def obtenerAccesos(token,plaza_id):

    # API URI
    url = 'https://app.pogendata.com/api/data/accesos'

    # Request header
    encabezado = {
        'Authorization' : 'Bearer ' + token
    }

    # Request parameters
    parametros = {
        'plaza_id' : plaza_id
    }

    # Make request and fetch response
    respuesta = requests.post(url, params=parametros, headers=encabezado)

    # Response as JSON 
    json = respuesta.json()

    # Send data
    return json
                        </code>
                    </pre>                    
                </div>
            </div>
            
            <div class="titulo1">
                Entry traffic information
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parameters:
                </div>                
                <ul>
                    <li>
                        <strong>Token</strong>, the valid access token obtained with login call it must sent 
                        on the request's header as Authorization bearer
                    </li>                    
                    <li>
                       <strong>plaza_id</strong>: location id for the location to query (integer value), if ommited
                       the response will include the entries for all the associated locations
                    </li>
                    <li>
                        <strong>inicio</strong>: date and time for the starting period of time we want to fetch 
                        the information, formatt 'YYYY-MM-DD HH:mm:ss'
                    </li>
                    <li>
                        <strong>fin</strong>: date and time for the ending period of time we want to fetch 
                        the information, formatt 'YYYY-MM-DD HH:mm:ss'
                    </li>
                    <li>
                        <strong>tipo</strong>: segmentation type for the report, the valid options are 
                        days,hours,weeks,months,years
                    </li>
                    <li>
                        <strong>pagina</strong>: if, due the ammount of entries, the response requieres multiple
                        pages, this parameter establishes the page number of the response to be retrieved.
                        Default value is 0
                    </li>
                    <li>
                        <strong>agrupar</strong>: Boolean type that establishes if the response must apply groupin to 
                        the response. Default value is false
                    </li>
                    <li>
                        <strong>campo_orden</strong>: Possible values ## fecha ó inserción ##, defines the order of the information
                    </li>
                    <li>
                        <strong>orden</strong>: Defines de sorting order for the entries in the response. Valid values are asc or desc
                    </li>
                </ul>
                <div class="categoria">
                    Request's Details:
                </div>                
                <ul>
                    <li> <strong>url</strong>: https://app.pogendata.com/api/data/datos</li>
                    <li> <strong>method</strong>: post</li>
                </ul>
                <div class="categoria">
                    Response:
                </div>                
                <ul>
                    <li>JSON Object, information for the traffic available in the "data" field
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="js">
/* Function to fetch entries */
async function obtenerEntradas(token,plaza_id,inicio,fin,tipo,pagina,agrupar,campo_orden,orden){

    /* API URI */
    let url = 'https://app.pogendata.com/api/data/datos';

    /* Create a FormData object to be sent in the request */
    let fd = new FormData();

    /* Add parameters to the formData object */
    fd.append('plaza_id',plaza_id);
    fd.append('inicio',inicio);
    fd.append('fin',fin);
    fd.append('tipo',tipo);
    fd.append('pagina',pagina);
    fd.append('agrupar',agrupar);
    fd.append('campo_orden',campo_orden);
    fd.append('orden',orden);

    /* Make request use POST method, include in HEADERS Authorization Bearer the API token */    fetch(url,{
    method:'post',
    headers:{
        Authorization:`bearer ${token}`
    },
    body:fd
    }).then(respuesta=>{
    
    /* Fetch as JSON */
    respuesta.json()
    .then(json=>{

        /* Reponse data as an array */
        console.log(json);

    });
    }).catch(error=>console.log(error));

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Fucntion to fetch entries between specific dates  */
function obtenerEntradas($token,$plaza_id,$inicio,$fin,$tipo,$pagina,$agrupar,$campo_orden,$orden){

    /* API URI */
    $url = 'https://app.pogendata.com/api/data/datos';
    
    /* Array with request's parameters */
    $arregloDatos = array(
        'plaza_id'=>$plaza_id,
        'inicio'=>$inicio,
        'fin'=>$fin,
        'tipo'=>$tipo,
        'pagina'=>$pagina,
        'agrupar'=>$agrupar,
        'campo_orden'=>$campo_orden,
        'orden'=>$orden
    );

    /* Make request object */
    $datos = http_build_query($arregloDatos);

    /* Array with connection options */
    $opciones = array(
        'http'=>array(
            'method'=>'POST',
            'header'=>array(
                'Authorization: Bearer ' . $token,
                'Content-type: application/x-www-form-urlencoded'
            ),
            'content'=>$datos
        )
    );

    /* Create request's context */
    $contexto = stream_context_create($opciones);

    /* Execute request */
    $respuesta = file_get_contents($url,false,$contexto);

    /* Response to array */
    $json = json_decode($respuesta);

    /* Sent fecthed data */
    return $json;

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class="vb">
/* Clase modelo para la respuesta entradas */
public class RespuestaEntradas
{
    public int Pagina { get; set; }

    public int Registros { get; set; }

    public List&lt;ClaseEntradas&gt; Datos { get; set; }
}

/* Class for entries */
public class ClaseEntradas
{
    public String Entradas { get; set; }
    public int Plaza_id { get; set; }
    public int Acceso_id { get; set; }
    public String Fecha { get; set; }
}

/* Method to fetch entries */
public static async Task&lt;RespuestaEntradas&gt; Entradas(String token, int plaza_id, String inicio, String fin, String tipo, int pagina, bool agrupar, String campo_orden, String orden)
{

    /* API URI */
    String direccion = "https://app.pogendata.com/api/data/datos";

    /* Create HTTP Client */
    using(var clienteHttp = new HttpClient())
    {
    
        /* Add access token to header */
        clienteHttp.DefaultRequestHeaders.Add("Authorization", $"Bearer {token}");

        /* Create object for parameters */
        var objetoParametros = HttpUtility.ParseQueryString(String.Empty);

        /* Add parameters */
        objetoParametros["plaza_id"] = $"{plaza_id}";
        objetoParametros["inicio"] = inicio;
        objetoParametros["fin"] = fin;
        objetoParametros["tipo"] = tipo;
        objetoParametros["pagina"] = $"{pagina}";
        objetoParametros["agrupar"] = $"{agrupar}";
        objetoParametros["campo_orden"] = campo_orden;
        objetoParametros["orden"] = orden;

        /* Create Query String */
        var cadenaParametros = objetoParametros.ToString();

        /* Create URL for the request with API URI and Query String */
        direccion += "?" + cadenaParametros;

        /* Execute request */
        var respuesta = await clienteHttp.PostAsync(direccion,null);

        /* Fetch response as String */
        var informacion = await respuesta.Content.ReadAsStringAsync();

        /* Create a JSON */
        var json = JsonConvert.DeserializeObject&lt;RespuestaEntradas&gt;(informacion);

        /* Sent Data */
        return json;

    }
}     
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Import additional libraries
import additional libraries

# Method to fetch entries
def obtenerEntradas(token, plaza_id, inicio, fin, tipo, pagina, agrupar, campo_orden, orden):

    # API URI
    url = 'https://app.pogendata.com/api/data/datos'
    # API URI

    # Request header
    encabezados = {
        'Authorization' : 'Bearer ' + token
    }

    # Request parameters
    parametros = {
        'plaza_id' : plaza_id,
        'inicio' : inicio,
        'fin' : fin,
        'tipo' : tipo,
        'pagina' : pagina,
        'agrupar' : agrupar,
        'campo_orden' : campo_orden,
        'orden' : orden,
    }

    # Make request and fetch response
    respuesta = requests.post(url, params=parametros, headers=encabezados)

    # Response as JSON 
    json = respuesta.json()

    # Send data
    return json['datos']
                        </code>
                    </pre>                    
                </div>
            </div>
            
            <div class="titulo1">
                Objectives
            </div>

            <div class="detalles">
                <div class="categoria">
                    Parameters:
                </div>                
                <ul>
                    <li>
                        <strong>Token</strong>, the valid access token obtained with login call it must sent 
                        on the request's header as Authorization bearer
                    </li>                    
                    <li>
                       <strong>plaza_id</strong>: location id for the location to query (integer value), if ommited
                       the response will include the entries for all the associated locations
                    </li>
                    <li>
                        <strong>inicio</strong>: date and time for the starting period of time we want to fetch 
                        the information, formatt 'YYYY-MM-DD HH:mm:ss'
                    </li>
                    <li>
                        <strong>fin</strong>: date and time for the ending period of time we want to fetch 
                        the information, formatt 'YYYY-MM-DD HH:mm:ss'
                    </li>
                </ul>
                <div class="categoria">
                    Request's Details:
                </div>                
                <ul>
                    <li> <strong>url</strong>: https://app.pogendata.com/api/data/objetivos</li>
                    <li> <strong>method</strong>: get</li>
                </ul>
                <div class="categoria">
                    Response:
                </div>                
                <ul>
                    <li>JSON Object, list the information for the objectives in the "data" field
                    </li>
                </ul>                                
            </div>

            <div class="contieneTabs">
                <div class="tab" @click="animarPaneles(0)" v-bind:class="{seleccionado:pa===0}">Javascript</div>
                <div class="tab" @click="animarPaneles(1)" v-bind:class="{seleccionado:pa===1}">PHP</div>
                <div class="tab" @click="animarPaneles(2)" v-bind:class="{seleccionado:pa===2}">Net</div>
                <div class="tab" @click="animarPaneles(3)" v-bind:class="{seleccionado:pa===3}">Python</div>
            </div>

            <div class="pizarra">
                <div class="c_js">
                    <pre v-highlightjs>
                        <code class="js">
/* Function to fetch objetives */
async function obtenerObjetivos(token,plaza_id,inicio,fin){

    /* API URI */
    let url = 'https://app.pogendata.com/api/data/objetivos';

    /* GET method uses a full URL*/
    url += `?plaza_id=${plaza_id}&inicio=${inicio}&fin=${fin}`;

    /* Make request use POST method, include in HEADERS Authorization Bearer the API token */
    fetch(url,{
    method:'get',
    headers:{
        Authorization:`bearer ${token}`,
        'Content-Type':'application/json',
    },
    }).then(respuesta=>{
    
    /* JSON response */
    respuesta.json()
    .then(json=>{

        /* Objectives as an array */
        console.log(json);

    });
    }).catch(error=>console.log(error));

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_php">
                    <pre v-highlightjs>
                        <code class="PHP">
/* Function to get the objetives between specific dates */
function obtenerObjetivos($token,$plaza_id,$inicio,$fin){

    /* API URI */
    $url = 'https://app.pogendata.com/api/data/objetivos';

    /* Array with request's parameters */
    $arregloDatos = array(
        'plaza_id'=>$plaza_id,
        'inicio'=>$inicio,
        'fin'=>$fin
    );
    
    /* Make request object */
    $datos = http_build_query($arregloDatos);

    /* Array with connection options */
    $opciones = array(
        'http'=>array(
            'method'=>'GET',
            'header'=>array(
                'Authorization: Bearer ' . $token,
                'Content-type: application/x-www-form-urlencoded'
            ),
        )
    );

    /* Create request's context */
    $contexto = stream_context_create($opciones);

    /* Execute request */
    $respuesta = file_get_contents($url."?".$datos,false,$contexto);

    /* Response to array */
    $json = json_decode($respuesta);

    /* Sent fecthed data */
    return $json;

}
                        </code>
                    </pre>                    
                </div>
                <div class="c_net">
                    <pre v-highlightjs>
                        <code class="vb">
/* Class for Objective Response*/
public class RespuestaObjetivo
{
    public int Success { get; set; }
    public FechasObjetivo FechasObjetivo { get; set; }
    public List&lt;ClaseObjetivos&gt; Objetivo { get; set; }
}

/* Class Objective Dates */
public class FechasObjetivo
{
    public String Inicio { get; set; }
    public String Fin { get; set; }
}

/* Class Objective */
public class ClaseObjetivos
{
    public int Total { get; set; }
    public int Plaza_id { get; set; }
}

/* Method to fetch objectives */
public static async Task&lt;RespuestaObjetivo&gt; Objetivos(String token, int plaza_id, String inicio, String fin)
{
    /* API URI */
    var direccion = "https://app.pogendata.com/api/data/objetivos";

    /* Create Parameters Object */
    var objetoParametros = HttpUtility.ParseQueryString(String.Empty);

    /* Add parameters to the object */
    //objetoParametros["plaza_id"] = $"{plaza_id}";
    objetoParametros["inicio"] = inicio;
    objetoParametros["fin"] = fin;

    /* Build string with parameters */
    var cadenaParametros = objetoParametros.ToString();

    /* Add parameters to URL */
    direccion += "?" + cadenaParametros;

    /* Create httpClient */
    using (var clienteHttp = new HttpClient())
    {

        /* Add toket to Request's header */
        clienteHttp.DefaultRequestHeaders.Add("Authorization", $"Bearer {token}");

        /* Execute request */
        var respuesta = await clienteHttp.GetStringAsync(direccion);

        var json = JsonConvert.DeserializeObject&lt;RespuestaObjetivo&gt;(respuesta);

        /* Send data */
        return json;

    }
}
                        </code>
                    </pre>                    
                </div>
                <div class="c_py">
                    <pre v-highlightjs>
                        <code class="Python">
# Include additional libraries
import requests

# Method to fetch objectives
def obtenerObjetivo(token,plaza_id,inicio,fin):

    # API URI
    url = 'https://app.pogendata.com/api/data/objetivos'

    # Request header
    encabezado = {
        'Authorization' : 'Bearer ' + token
    }

    # Request parameters
    parametros = {
        'plaza_id' : plaza_id,
        'inicio' : inicio,
        'fin' : fin
    }

    # Make request and fetch response
    respuesta = requests.get(url, headers=encabezado, params=parametros)

    # Response as JSON 
    json = respuesta.json()

    # Send data
    return json['objetivo']
                        </code>
                    </pre>                    
                </div>
            </div>

            <footer>
                ©2021 Pogen, sistemas@pogen.com
            </footer>

    </div>

</template>
<!-- Código vue -->
<script>

    export default{

        data(){
            return{
                pa:0
            }
        },

        methods:{            
            animarPaneles(indice){
                let clases = ['c_js','c_php','c_net','c_py'];
                this.pa = indice;
                let contador = 0;
                clases.forEach(cU=>{
                    let pa = document.getElementsByClassName(cU);
                    for(let c = 0; c < pa.length; c++){
                        pa[c].style.height = contador === indice ? '50vh' : '0vh';
                    }
                    contador++;
                })
            }
        }

    }

</script>


<!-- Esilo propio -->
<style scoped>
    .contieneTabs{
        display: flex;
    }
    .tab{
        width:10em;
        text-align: center;
        background-color: #ccc;
        padding:.3em 0em;
        border: solid 1px #888;
        cursor: pointer;
    }
    .pizarra{
        height: 50vh;
        margin-bottom: 3em;
    }
    .c_js, .c_php, .c_net, .c_py{
        top: 0em;
        left: 0em;
        height: 0em;
        width: 100%;
        overflow-y: scroll;
        color: white;
        transition:height .3s linear;
    }
    .c_js{
        height: 50vh;
    }
    .seleccionado{
        background-color: #555;
        color: white;
    }
    .titulo1 {
    margin-top: 1em;
    font-size: 1.2em;
    color: #5a5a5a;
    padding-left: 1em;
    font-weight: 500;
    }
    .parrafo {
    text-align: justify;
    text-indent: 1em;
    padding: 0.7em;
    font-size: 0.9em;
    color: #5a5a5a;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    }
    .detalles {
    margin-top: 1.5em;
    padding-left: 1.5em;
    color: #5a5a5a;
    font-size: 0.9em;
    }
    .categoria {
    font-size: 1em;
    font-weight: 400;
    }

    .mt2em {
    margin-top: 2em;
    }
    footer {
    margin-top: 5em;
    text-align: center;
    background-color: #363636;
    border-radius: 0 0 0.5em 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: white;
    font-weight: 500;
    font-size: 0.8em;
    }                    
</style>